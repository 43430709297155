<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Code</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.code"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Remark</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-textarea
                  outlined
                  flat
                  rows="3"
                  background-color="white"
                  v-model="form.remark"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3">
                <v-subheader>Used</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-checkbox background-color="white" v-model="form.used"></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="1" lg="1" class="py-0">
        <v-row class="py-0">
          <v-col cols="12" class="mb-4 py-0">
            <v-btn color="primary" @click="submit">
              <v-icon large>
                mdi-content-save
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: "create-pph-23-code",
  data: () => ({
    defaultForm: {
      code: "",
      remark: "",
      used: false,
    },
    form: {},
    valid: true,
  }),

  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.$store.commit("SET_LOADER", true);
        await this.$store
          .dispatch("pph/createPph23Code", this.form)
          .then(() => this.$store.commit("SET_LOADER", false))
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      }
    },
  },

  mounted() {
    this.form = Object.assign({}, this.defaultForm);
  },
};
</script>

<style></style>
